// WindowedDocumentViewer.tsx
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Page, pdfjs, Document } from 'react-pdf';
import { memo, useRef, useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { CircularProgress } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppSelector } from 'src/store/store';

import DraggableWindow from 'src/components/draggable-window/draggable-window';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs` as string;

interface Props {
  onClose: () => void;
}

function getFileExtension(filename: string): string {
  return filename.split('.').pop()?.toLowerCase() || '';
}

interface DocumentDimensions {
  width: string;
  height: string;
  minWidth?: string;
}

function getDocumentDimensions(fileType: string): DocumentDimensions {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const maxHeight = Math.min(viewportHeight * 0.9, 900); // 90vh or 900px, whichever is smaller

  switch (fileType) {
    case 'pdf':
      return {
        width: '800px',
        height: `${maxHeight}px`,
        minWidth: '800px'
      };
    case 'doc':
    case 'docx':
      return {
        width: `${Math.min(viewportWidth * 0.75, 1200)}px`,
        height: `${maxHeight}px`,
        minWidth: '800px'
      };
    case 'xls':
    case 'xlsx':
      return {
        width: '90vw',
        height: `${maxHeight}px`,
        minWidth: '1000px'
      };
    case 'ppt':
    case 'pptx':
      return {
        width: `${Math.min(viewportWidth * 0.8, 1200)}px`,
        height: `${maxHeight}px`,
        minWidth: '800px'
      };
    default:
      return {
        width: '800px',
        height: `${maxHeight}px`,
        minWidth: '800px'
      };
  }
}

const PDFViewer = memo(({ url, onError }: { url: string; onError: () => void }) => {
  const { t } = useTranslate();
  const [pageCount, setPageCount] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageCount(numPages);
  };

  // memoize the file object (prevent re-rendering on nav)
  const file = useMemo(() => ({ url }), [url]);

  return (
    <Document
      className="document-viewer-document"
      loading={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      }
      error={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          {t('documents.document_viewer.load_error')}
        </Box>
      }
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onError}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        {Array.from(new Array(pageCount || 0), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={1}
            width={800}
            loading={
              <Box sx={{
                height: 800,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <CircularProgress size={24} />
              </Box>
            }
          />
        ))}
      </Stack>
    </Document>
  );
});

const OfficeViewer = memo(({ url, dimensions }: { url: string; dimensions: DocumentDimensions }) => {
  const encodedUrl = encodeURIComponent(url);
  const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;

  return (
    <Box sx={{ height: '100%', overflowY: 'hidden' }}>
      <iframe
        src={viewerUrl}
        style={{
          border: 'none',
          width: '100%',
          height: '100%'
        }}
        title="document-viewer"
      />
    </Box>
  );
});

export default function WindowedDocumentViewer({ onClose }: Props) {
  const documentRef = useRef<HTMLDivElement>(null);
  const documentViewerState = useAppSelector((state) => state.app.documentViewer);
  const [viewerError, setViewerError] = useState(false);

  const fileType = useMemo(() =>
    getFileExtension(documentViewerState.fileUrl || ''),
    [documentViewerState.fileUrl]
  );

  const dimensions = useMemo(() =>
    getDocumentDimensions(fileType),
    [fileType]
  );

  useEffect(() => {
    const scrollKey = `doc-scroll-${documentViewerState.fileUrl}`;
    return () => {
      if (documentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        sessionStorage.setItem(scrollKey, documentRef.current.scrollTop.toString());
      }
    };
  }, [documentViewerState.fileUrl]);

  useEffect(() => {
    const scrollKey = `doc-scroll-${documentViewerState.fileUrl}`;
    const savedScrollPosition = sessionStorage.getItem(scrollKey);
    if (savedScrollPosition && documentRef.current) {
      documentRef.current.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, [documentViewerState.fileUrl]);

  const documentViewer = useMemo(() => {
    if (!documentViewerState.fileUrl) return null;

    if (viewerError) {
      return <OfficeViewer url={documentViewerState.fileUrl} dimensions={dimensions} />;
    }

    switch (fileType) {
      case 'pdf':
        return <PDFViewer url={documentViewerState.fileUrl} onError={() => setViewerError(true)} />;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
        return <OfficeViewer url={documentViewerState.fileUrl} dimensions={dimensions} />;
      default:
        return <OfficeViewer url={documentViewerState.fileUrl} dimensions={dimensions} />;
    }
  }, [documentViewerState.fileUrl, viewerError, dimensions, fileType]);

  return (
    <DraggableWindow
      onClose={onClose}
      title={documentViewerState.title}
      url={documentViewerState.fileUrl}
    >
      <Box
        ref={documentRef}
        sx={{
          width: dimensions.width,
          height: dimensions.height,
          overflowY: 'auto',
          p: 2,
          '& .react-pdf__Document': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        {documentViewer}
      </Box>
    </DraggableWindow>
  );
}