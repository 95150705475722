import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { MobileDatePicker } from '@mui/x-date-pickers';
import {
  Dialog,
  Select,
  Button,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useUpdateProfileDocumentMutation } from 'src/services/candidates/candidates.service';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  documentId: string;
};

export default function EditProfileDocumentModal({ open, onClose, documentId }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [updateDocument, { isLoading: isUpdatingDocument }] = useUpdateProfileDocumentMutation();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [selectedVisiblity, setSelectedVisiblity] = useState<VisibilityRule>(VisibilityRule.PUBLIC);

  const handleUpdateDocument = useCallback(async () => {
    try {
      await updateDocument({
        documentId,
        data: {
          visibility_rule: selectedVisiblity,
          expiry_date: selectedDate?.toISOString(),
        },
      }).unwrap();

      onClose();
      enqueueSnackbar(t('profile.api.edit_document.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t('profile.api.edit_document.default_error'), { variant: 'error' });
    }
  }, [documentId, enqueueSnackbar, onClose, selectedDate, selectedVisiblity, t, updateDocument]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('profile.documents.edit_modal.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} paddingTop={2}>
          <Grid xs={6}>
            <FormControl fullWidth sx={{ width: '100%' }}>
              <InputLabel id="doc-type-label">{t('profile.documents.visiblity')}</InputLabel>
              <Select
                id="doc-type"
                labelId="doc-type-label"
                label={t('profile.documents.visiblity')}
                value={selectedVisiblity}
                onChange={(e) => setSelectedVisiblity(e.target.value as VisibilityRule)}
                fullWidth
              >
                <MenuItem value={VisibilityRule.PUBLIC}>
                  {t('enums.visibility_rule.public')}
                </MenuItem>
                <MenuItem value={VisibilityRule.HIDDEN}>
                  {t('enums.visibility_rule.hidden')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6}>
            <MobileDatePicker
              label={t('profile.documents.expiry_date')}
              value={null}
              onChange={setSelectedDate}
              sx={{ width: '100%' }}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'today', 'cancel', 'accept'],
                },
              }}
            />
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isUpdatingDocument}
              onClick={handleUpdateDocument}
            >
              {t('common.edit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
