import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

import { useAuth } from 'src/auth/hooks/useAuth';

const ErrorSentinel: React.FC = () => {

  const { isAuthenticated, context } = useAuth();

  useEffect(() => {
    if (isAuthenticated && context) {
      Sentry.setUser(context);
    }
    else {
      Sentry.setUser(null);
    }
  }, [isAuthenticated, context]);

  // You can optionally render some content here,
  // but in this case we're just redirecting
  return null;
};

export default ErrorSentinel;
