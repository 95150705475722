/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// Import i18n
import 'src/locales/i18n';

// ----------------------------------------------------------------------

import { useEffect } from 'react';
import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ThemeProvider from 'src/theme';

import { SnackbarProvider } from 'notistack';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { ErrorBoundary } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Provider } from 'react-redux';
import ErrorSentinel from 'src/components/error-tracking/sentinel';
import LoadingSnackbar from './components/toast/LoadingSnackbar';
import { LocalizationProvider } from './locales';
import SentryErrorPage from './pages/SentryErrorPage';
import PageViewTracker from './routes/components/page-view-tracker';
import { store } from './store/store';
import "./utils/yup-extensions.utils";

// ----------------------------------------------------------------------

declare module 'notistack' {
  interface VariantOverrides {
    // adds `loading` variant      
    loading: true
  }
}

export default function App() {

  const charAt = `
  ░░░░░██╗░█████╗░██████╗░████████╗███████╗████████╗██████╗░██╗░██████╗
  ░░░░░██║██╔══██╗██╔══██╗╚══██╔══╝██╔════╝╚══██╔══╝██╔══██╗██║██╔════╝
  ░░░░░██║██║░░██║██████╦╝░░░██║░░░█████╗░░░░░██║░░░██████╔╝██║╚█████╗░
  ██╗░░██║██║░░██║██╔══██╗░░░██║░░░██╔══╝░░░░░██║░░░██╔══██╗██║░╚═══██╗
  ╚█████╔╝╚█████╔╝██████╦╝░░░██║░░░███████╗░░░██║░░░██║░░██║██║██████╔╝
  ░╚════╝░░╚════╝░╚═════╝░░░░╚═╝░░░╚══════╝░░░╚═╝░░░╚═╝░░╚═╝╚═╝╚═════╝░
  `;

  useEffect(() => {
    console.info(`%c${charAt}`, 'color: #5BE49B');
  }, [charAt]);

  useScrollToTop();

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

  const options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'aud',
  };

  return (
    <Provider store={store}>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <Elements stripe={stripePromise} options={options}>
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider
                  Components={{
                    loading: LoadingSnackbar,
                  }}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                >
                  <ErrorBoundary fallback={<SentryErrorPage />}>
                    <ErrorSentinel />
                    <SettingsDrawer />
                    <ProgressBar />
                    <PageViewTracker />
                    <Router />
                  </ErrorBoundary>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </Elements>
        </SettingsProvider>
      </LocalizationProvider>
    </Provider>
  );
}
