import { useRef, useState, ReactNode, useEffect, MouseEvent } from 'react';

import { Paper, Stack, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

interface Position {
  x: number;
  y: number;
}

interface DraggableWindowProps {
  children: ReactNode;
  onClose: () => void;
  initialPosition?: Position;
  title?: string;
  url?: string;
}

export default function DraggableWindow({
  children,
  onClose,
  initialPosition = { x: 50, y: 20 },
  title,
  url
}: DraggableWindowProps) {
  const [position, setPosition] = useState<Position>(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState<Position>({ x: 0, y: 0 });
  const windowRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  useEffect(() => {
    // handle mouse move and up events 
    const handleMouseMove = (e: globalThis.MouseEvent) => {
      if (isDragging && windowRef.current) {
        // lock window position within viewport (window shouldnt be able to go off screen)
        const windowRect = windowRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // calculate new position
        let newX = e.clientX - dragOffset.x;
        let newY = e.clientY - dragOffset.y;

        // constrain X position
        const minX = 0;
        const maxX = viewportWidth - windowRect.width;
        newX = Math.max(minX, Math.min(newX, maxX));

        // Constrain Y position
        const minY = 0;
        const maxY = viewportHeight - windowRect.height;
        newY = Math.max(minY, Math.min(newY, maxY));

        // update position
        setPosition({
          x: newX,
          y: newY,
        });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      // attached to document level: make sure dragging beyond component boundary is handled
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragOffset]);

  // ensure initial position is within bounds
  useEffect(() => {
    // lock window position within viewport (window shouldnt be able to go off screen)
    if (windowRef.current) {
      const windowRect = windowRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let newX = position.x;
      let newY = position.y;

      // constrain X position
      const minX = 0;
      const maxX = viewportWidth - windowRect.width;
      newX = Math.max(minX, Math.min(newX, maxX));

      // constrain Y position
      const minY = 0;
      const maxY = viewportHeight - windowRect.height;
      newY = Math.max(minY, Math.min(newY, maxY));

      if (newX !== position.x || newY !== position.y) {
        setPosition({ x: newX, y: newY });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      ref={windowRef}
      sx={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 1300,
        minWidth: 600,
        maxWidth: '80vw',
        maxHeight: '90vh',
        overflow: 'hidden',
        boxShadow: 24,
      }}
      elevation={24}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 2,
          py: 1,
          cursor: isDragging ? 'grabbing' : 'grab',
          bgcolor: 'background.neutral',
          userSelect: 'none',
        }}
        onMouseDown={handleMouseDown}
      >
        <TruncatedTextField
          variant="subtitle2"
          text={title}
          limit={50}
          mode='characters'
          showMoreEnabled={false}
        />
        <Stack direction="row" spacing={1}>
          {url && (
            <IconButton
              size="small"
              onClick={() => window.open(url, '_blank')}
            >
              <Iconify icon="mingcute:external-link-line" />
            </IconButton>
          )}
          <IconButton size="small" onClick={onClose} sx={{ zIndex: 1000 }}>
            <Iconify icon="mi:close" />
          </IconButton>
        </Stack>
      </Stack>
      {children}
    </Paper>
  );
}