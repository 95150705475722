export enum BusinessErrorCodes {
  ResourceNotFound = '0_0001',
  GenericBadRequest = '0_0002',
  InvalidFileType = '0_0003',

  JobIsAlreadyClosed = '1_0001',
  JobIsAlreadyActive = '1_0002',
  JobIsAlreadyApproved = '1_0003',
  JobIsAlreadyVerified = '1_0004',
  JobIsNotActive = '1_0005',
  InvalidJobCloneTarget = '1_0006',
  InvalidSectionVisibility = '1_0007',
  LinkBehaviourNotSupported = '1_0008',
  JobClientNotAssigned = '1_0009',
  JobVerificationPending = '1_0010',
  JobNotVerified = '1_0011',
  RoleIsNotActive = '1_0012',
  LinkedJobCannotBeArchived = '1_0013',
  JobIsAlreadyArchived = '1_0014',
  PastShortlistDeadline = '1_0015',
  InvalidJobVerificationState = '1_0016',
  InvalidJobType = '1_0017',
  JobChangesNotRequested = '1_0018',

  SimilarStakeholderAssigned = '1_2001',
  InvalidStakeholderLabel = '1_2002',
  JobNotOpenForApplications = '1_2003',

  JobInviteExists = '1_3001',
  UnableToActionInvite = '1_3002',
  JobInviteExpired = '1_3003',
  InvalidInviteRecipient = '1_3004',

  JobLinkRequestExists = '1_4001',
  InvalidLinkRequestState = '1_4002',
  JobAlreadyLinked = '1_4003',
  InvalidJobLinkTarget = '1_4004',
  JobIndustryNotApproved = '1_4005',
  RecruitmentTypeRestriction = '1_4006',
  CannotLinkInternalJob = '1_4007',
  InvalidFeeSettings = '1_4008',


  ApplicationNotFound = '2_0001',
  CandidateAlreadyApplied = '2_0002',
  InvalidStageProgression = '2_0003',
  JobHasActiveApplication = '2_0004',
  NoApplicationsToShortlist = '2_0005',
  ApplicationHasFinalized = '2_0006',
  NoVacanciesAvailable = '2_0007',
  InvalidApplicationStage = '2_0008',
  ApplicationWithdrawn = '2_0009',
  CannotAllocateSameRecruiter = '2_0010',
  ApplicationArchived = '2_0011',
  ShortlistDeadlineExpired = '2_0012',
  ApplicationAlreadyShortlisted = '2_0013',
  ApplicationNotFinalized = '2_0014',
  ShortlistLimitReached = '2_0015',
  ApplyIntentExists = '2_0016',
  InvalidApplyIntentState = '2_0017',
  NoApplicationsToProgress = '2_0018',
  NoApplicationsToReject = '2_0019',
  ApplyIntentVerificationEmailRateLimited = '2_0020',

  MissingRequestedItems = '2_1001',
  InvalidResourceRequestStatus = '2_1002',

  InvalidStageChangeRequestStatus = '2_2001',
  StageChangeRequestExists = '2_2002',

  ScheduledTimeRequired = '2_3001',
  ClientParticipantRequired = '2_3002',
  CandidateParticipantRequired = '2_3003',
  TenantAvailabilityRequired = '2_3004',
  InvalidTenantParticipant = '2_3005',
  InvalidInterviewStatus = '2_3006',
  InvalidAvailabilitySlot = '2_3007',
  InvalidScheduledTime = '2_3008',
  InvalidParticipantCount = '2_3009',
  AvailabilitySlotsRequired = '2_3010',

  InvalidOfferStatus = '2_4001',
  PendingOffersExist = '2_4002',
  TenantCannotMakeOffer = '2_4003',
  MissingRequiredDocumentTypes = '2_4004',

  PlacementExists = '2_5001',


  FeatureLimitExceeded = '3_0001',
  InvalidProductQuantity = '3_0002',
  InvalidProductType = '3_0003',

  ActiveSubscriptionNotFound = '3_1000',
  ActiveSubscriptionExists = '3_1001',
  NoSubscriptionRequired = '3_1002',
  InvalidSubscriptionState = '3_1003',
  OpenInvoiceNotFound = '3_1004',

  InvoiceInvalidState = '3_2001',

  InvalidServicePaymentMethod = '3_3001',
  PaymentMethodNotAttached = '3_3002',
  PaymentMethodError = '3_3003',
  CannotRemoveDefaultPaymentMethod = '3_3004',

  BillingProviderError = '3_4000',

  InvalidPromoCode = '3_5001',


  BadTenantOperation = '4_0001',
  OrgInviteExists = '4_0002',
  VerifiedOrgDomainExists = '4_0003',
  CompanyProfileAlreadyActivated = '4_0004',
  SimilarCompanyProfileExists = '4_0005',
  InvitedAccountExists = '4_0006',
  OrgInviteExpired = '4_0007',
  InactiveOrganization = '4_0008',
  ProfileTypeCannotBeRemoved = '4_0009',
  OrganizationAdminRequired = '4_0010',
  AccountLocked = '4_0011',
  InvalidAccountStatus = '4_0012',
  CannotLockOwnAccount = '4_0013',
  SubTenantAlreadyActivated = '4_0014',
  TenantHierarchyNotSupported = '4_0015',
  OrganizationNotSubTenant = '4_0016',
  AccountNotMigratable = '4_0017',
  SubTenantSuspended = '4_0018',
  CannotUnlockAccount = '4_0019',

  ConnectionRequestExists = '4_1000',
  InvalidRequestStatus = '4_1001',
  InvalidRequestInvite = '4_1002',
  InvalidSwitchOperation = '4_1003',
  DefaultFeeSettingsNotConfigured = '4_1004',

  ActiveConnectionExists = '4_2000',
  InvalidConnectionTarget = '4_2001',
  InvalidConnectionStatus = '4_2002',
  OrganizationNotConnected = '4_2003',
  OrganizationDoesNotSupportIndustry = '4_2004',

  InternalContactAccount = '4_3000',
  CannotUpdateLinkedContact = '4_3001',


  CandidateProfileExists = '5_0001',
  SimilarReferenceExists = '5_0002',
  CannotCloneAttachedProfile = '5_0003',
  ExperienceAlreadyVerified = '5_0004',
  ExperienceVerificationPending = '5_0005',
  InvalidVerificationEmail = '5_0006',
  InvalidAvailabilityStatus = '5_0007',
  CannotDeletePrimaryProfile = '5_0008',
  CandidateInviteExpired = '5_0009',
  CandidateInviteExists = '5_0010',
  InvalidInviteStatus = '5_0011',
  CandidateAlreadyConnected = '5_0012',
  CandidateExportFailed = '5_0013',


  InvalidTermsForTenant = '6_0001',
  AccountAlreadyVerified = '6_0002',
  InactiveAccountOperation = '6_0003',
  PhoneNumberNotSet = '6_0004',
  PhoneAlreadyVerified = '6_0005',
  InvalidPhoneNumber = '6_0006',


  VerificationCodeExpired = '9_0001',
  VerificationCodeInvalid = '9_0002',
  VerificationCodeRateLimited = '9_0003',


  SkillExists = '10_0001',


  InvalidSettingValue = '11_0001',
  InvalidSettingForEntity = '11_0002',
  NonUpdatableSetting = '11_0003',


  InvalidDocumentType = '12_0001',
  InvalidTemplateType = '12_0002',


  CategoryExists = '13_0001',


  WebsiteScrapeFailed = '14_0001',
  ContentClassificationFailed = '14_0002',


  IntegrationExists = '15_0001',
  IntegrationNotConnected = '15_0002',
  IntegrationNotSupported = '15_0003',
  IntegrationConnectionFailed = '15_0004',
  InvalidConnectAttempt = '15_0005',
  IntegrationCategoryRestriction = '15_0006',
  IntegrationNotActive = '15_0007',
  InvalidIntegrationState = '15_0008',
  GenericIntegrationProviderError = '15_0009',
  IntegrationResourceExists = '15_0010',
  InvalidResourceOwner = '15_0011',


  FeatureNotSupportedForTenant = '16_0001',

  BusinessInfoCountryNotSupported = '17_0001',
}